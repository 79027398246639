<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            width="320"
        >
            <v-card>
                <v-card-title>
                    准备重置当前程序吗？
                </v-card-title>
                <v-card-text>
                    <div>重置程序将会删除当前已编写的代码</div>
                    <div>将其恢复成初始题目的状态</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="confirm()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    name: 'ResetClassroomDataDlg',
    props: ['title', 'text', 'keyword'],
    data() {
        return {
            dialog: false,
            id: "",
        };
    },
    methods: {
        openDlg(id = "") {
            this.dialog = true
            this.id = id
        },
        cancel() {
            this.id = ""
            this.dialog = false
        },
        confirm() {
            this.$emit("confirm", this.id)
            this.dialog = false
        },
        highlight(text = '', keyword = ''){
            if (keyword == '') {
                return text
            }
            if (text.includes(keyword)){
                return text.replace(
                    keyword, 
                    // 这里是替换成html格式的数据，最好再加一个样式权重，保险一点
                    '<span style="color: #9c27b0 !important;">'+ keyword +'</span>'
                )
            }
            // 不包含的话还用这个
            else {
                return text
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.content {
    color: #555;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
}
</style>
