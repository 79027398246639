<template>
    <div id="code-editor" class="code-editor"></div>
</template>

<script>
import ace from "ace-builds";
import "ace-builds/webpack-resolver"; // 在 webpack 环境中使用必须要导入
import "ace-builds/src-min-noconflict/theme-monokai"; // 默认设置的主题
import "ace-builds/src-min-noconflict/mode-python"; // 默认设置的语言模式
import "ace-builds/src-min-noconflict/ext-language_tools"; // 自动补全

export default {
    name: 'ace-editor',
    mounted() {
        this.initAce();
    },
    data() {
        return {
            editor: null,
        };
    },
    methods: {
        initAce() {
            if (!this.editor) {
                this.editor = ace.edit("code-editor");
                this.editor.setTheme("ace/theme/monokai");
                this.editor.session.setMode("ace/mode/python");
                this.editor.session.setUseWrapMode(true);
                this.editor.setOptions({
                    selectionStyle: "text",
                    hScrollBarAlwaysVisible: false,
                    vScrollBarAlwaysVisible: false,
                    fontSize: "18px",
                    showLineNumbers: true,
                    showFoldWidgets: true,
                    highlightActiveLine: true,
                    displayIndentGuides: false,
                    showInvisibles: false,
                    showPrintMargin: false,
                    printMargin: false,
                    printMarginColumn: false,
                    fixedWidthGutter: false,
                    wrap: true,

                    // spellcheck: true,
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                });
            }
        },
        resize() {
            if (this.editor) {
                this.editor.resize()
            }
        },
        focus() {
            if (this.editor) {
                this.editor.focus()
            }
        },
        setValue(val, cursorPos = -1) {
            if (this.editor) {
                this.editor.setValue(val, cursorPos)
            }
        },
        getValue() {
            if (this.editor) {
                return this.editor.getValue()
            }
            return ""
        },
        getFontSize() {
            if (this.editor) {
                return this.editor.getFontSize()
            }
            return 0
        },
        setFontSize(size) {
            if (this.editor) {
                this.editor.setFontSize(size)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.code-editor {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
}
</style>

<style lang="scss">
/******************************
 * 代码编辑器相关
 ******************************/

/*代码编辑器相关*/
.code-area .code-editor {
    line-height: 30px !important;
}

/*优化编辑器样式配色*/
.ace-tomorrow-night-eighties {
    background-color: #272822 !important;
    color: #cccccc;
}
.ace-tomorrow-night-eighties .ace_gutter {
    background: #2f3129 !important;
    color: #8f908a !important;
}
.ace-tomorrow-night-eighties .ace_print-margin {
    width: 1px;
    background: #2f3129 !important;
}
.ace-tomorrow-night-eighties.ace_multiselect .ace_selection.ace_start {
    box-shadow: 0 0 3px 0px #272822 !important;
}

/*优化编辑器样式配色*/
.ace-monokai {
    background-color: #1f1e2e !important;
    color: #cccccc;
}
.ace-monokai .ace_gutter {
    background: #1f1e2e !important;
    color: #8f908a !important;
}
.ace-monokai .ace_gutter-active-line {
    background-color: #ffc107;
}
.ace-monokai .ace_print-margin {
    width: 1px;
    background: #2f3129 !important;
}
.ace-monokai.ace_multiselect .ace_selection.ace_start {
    box-shadow: 0 0 3px 0px #272822 !important;
}

.ace_scrollbar-v {
    overflow-y: hidden !important;
}
</style>
