<template>
    <v-overlay
        :absolute="absolute"
        :opacity="opacity"
        :value="overlay"
    >
        <div class="help-list"
        >
            <div class="help-col">
                <div class="help-title">输入/输出</div>
                <div class="help-name">print</div>
                <div class="help-eg">print("Hello world!")</div>
                <div class="help-spacer"></div>
                <div class="help-name">input</div>
                <div class="help-spacer"></div>
            </div>
            <div class="help-col">
                <div class="help-title">字符串</div>
                <div class="help-name">+</div>
                <div class="help-name">*</div>
                <div class="help-name">in | not in</div>
                <div class="help-name">[] | [:]</div>
                <div class="help-spacer"></div>
            </div>
            <div class="help-col">
                <div class="help-title">内置函数</div>
                <div class="help-name">abs</div>
                <div class="help-name">bin</div>
                <div class="help-name">hex</div>
                <div class="help-name">chr</div>
                <div class="help-name">ord</div>
                <div class="help-name">len</div>
                <div class="help-name">max</div>
                <div class="help-name">min</div>
                <div class="help-name">pow</div>
                <div class="help-name">range</div>
                <div class="help-name">round</div>
                <div class="help-name">sum</div>
                <div class="help-name">type</div>
                <div class="help-spacer"></div>
            </div>
            <div class="help-col">
                <div class="help-title">列表</div>
                <div class="help-name">定义 []</div>
                <div class="help-name">定义 list()</div>
                <div class="help-name">+</div>
                <div class="help-name">*</div>
                <div class="help-name">in</div>
                <div class="help-name">len</div>
                <div class="help-name">[::]</div>
                <div class="help-name">append</div>
                <div class="help-name">insert</div>
                <div class="help-name">remove</div>
                <div class="help-name">pop</div>
                <div class="help-name">del</div>
                <div class="help-name">clear</div>
                <div class="help-name">index</div>
                <div class="help-name">count</div>
                <div class="help-name">sort</div>
                <div class="help-name">reverse</div>
                <div class="help-spacer"></div>
            </div>
        </div>
    </v-overlay>
</template>

<script>
export default {
    name: 'HelpFuncListDlg',
    data() {
        return {
            absolute: true,
            opacity: 1,
            overlay: false,
        };
    },
    methods: {
        switchDlg() {
            this.overlay = !this.overlay
        },
        closeDlg() {
            this.overlay = false
        },
    }
};
</script>

<style lang="scss" scoped>
.help-list {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;

    .help-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 250px;
        // flex: 1;
        text-align: left;
    }

    .help-title {
        font-size: 17px;
        font-weight: bold;
        color: orange;
        margin-bottom: 10px;
    }
    .help-name {
        font-size: 16px;
        font-weight: bold;
        color: #e94b4b;
    }
    .help-eg {
        font-size: 16px;
        font-weight: bold;
        color: #eee;
        white-space: nowrap;
    }
    .help-spacer {
        width: 100%;
        margin-bottom: 10px;
    }
}
</style>
