<template>
    <div
        id="terminal"
        ref="terminal"
        class="terminal-area"
        readonly="readonly"
    ></div>
</template>

<script>
import { Terminal } from 'xterm';
import { FitAddon } from "xterm-addon-fit";
import 'xterm/css/xterm.css';

export default {
    name: 'xterm',
    mounted() {
        this.$nextTick(() => {
            this.initTerminal();
        })
    },
    props: [
        'isRunning',
        'inputResolve',
    ],
    data() {
        return {
            xterm: null,
            xtermInput: "",
            fitAddon: null,
            xtermLineNum: 1,
        };
    },
    methods: {
        initTerminal() {
            let that = this
            if (!this.xterm) {
                this.xterm = new Terminal({
                    // cols: 40,
                    // rows: 10,
                    lineHeight: 1.3,
                    fontSize: 16,
                    cursorBlink: true, // 光标闪烁
                    cursorStyle: 'block', // 光标样式  null | 'block' | 'underline' | 'bar'
                    scrollback: 800, // 回滚
                    tabStopWidth: 4, // 制表宽度
                    screenKeys: true, //
                    convertEol: true, // 回车后从最左侧开始
                    theme: {
                        // foreground: "#7e9192", //字体
                        background: "#1c1b25", //背景色
                        // cursor: "help", //设置光标
                    }
                })

                this.fitAddon = new FitAddon();
                this.xterm.loadAddon(this.fitAddon);
                this.xterm.open(document.getElementById('terminal'));
                this.fitAddon.fit();

                this.xterm.prompt = () => {
                    that.xterm.write('\r\n');
                }

                that.xterm.onKey(e => {
                    // 先判断 altKey/metaKey + Enter快捷键
                    if ((e.domEvent.altKey || e.domEvent.metaKey) && e.domEvent.keyCode === 13) {
                        if (that.isRunning) {
                            that.$emit('stop')
                        } else {
                            that.$emit('play')
                        }
                        return
                    }

                    // 处理用户输入等
                    if (!that.isRunning) {
                        return
                    }

                    const printable = !e.domEvent.altKey && !e.domEvent.altGraphKey && !e.domEvent.ctrlKey && !e.domEvent.metaKey;

                    // 回车
                    if (e.domEvent.keyCode === 13) {
                        let input = that.xtermInput
                        that.xterm.prompt(input);
                        that.xtermInput = ""

                        if (that.inputResolve) {
                            that.inputResolve(input)
                        }

                    // 删除（退格）Backspace
                    } else if (e.domEvent.keyCode === 8) {
                        // Do not delete the prompt
                        // if (that.xterm._core.buffer.x > 2) {
                            that.xterm.write('\b \b');
                        // }
                        that.xtermInput = that.xtermInput.substring(0, that.xtermInput.length - 1)

                    } else if (printable) {
                        that.xtermInput += e.key
                        that.xterm.write(e.key)
                    }
                });

            } else {
                this.xterm.clear()
            }
        },
        resize() {
            try {
                this.fitAddon.fit();

                // 窗口大小改变时触发xterm的resize方法，向后端发送行列数，格式由后端决定
                // xterm.onResize(size => {
                //     _this.onSend({ Op: "resize", Cols: size.cols, Rows: size.rows });
                // });
            } catch (e) {
                // console.log("e", e.message);
            }
        },
        focus() {
            if (this.xterm) {
                this.xterm.focus()
            }
        },
        clear() {
            this.xtermLineNum = 1

            if (this.xterm) {
                this.xterm.clear()
            }
        },
        stop() {
            let input = this.xtermInput
            if (this.xterm) {
                this.xterm.prompt(input);
            }
            this.xtermInput = ""

            if (this.inputResolve) {
                this.inputResolve(input)
            }

            if (this.xterm) {
                this.xterm.writeln("程序结束");
            }
        },
        write(text) {
            this.xterm.write('\x1b[1;33m' + (this.xtermLineNum++).toString() + '\x1b[37m ' + text)
        },
        writeln(text) {
            this.xterm.writeln(text)
        }
    }
}
</script>

<style lang="scss">
.xterm .xterm-viewport {
  /* On OS X this is required in order for the scroll bar to appear fully opaque */
  background-color: transparent;
  overflow-y: scroll;
  cursor: default;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  scrollbar-color: var(--highlight) var(--dark);
  scrollbar-width: thin;
}

.xterm-viewport::-webkit-scrollbar {
  background-color: var(--dark);
  width: 5px;
}

.xterm-viewport::-webkit-scrollbar-thumb {
  background: var(--highlight);
}
</style>
